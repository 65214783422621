
@page {
    size: A4;
    margin: 0;
  }
  
  @media print {
    html, body {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    body {
        display: flex;
        flex-direction: row;
    }

    #pro_section {
        flex-direction: column;
    }

    .page {
        height: 296.8mm;
        margin: 0;
        padding: 0;
    }

    #perso_section {
        flex: 1;
        min-width: 30vw;
    }

    #subsection_split {
        display: none;
    }
  }
  
  
  @media not print {
    @media (min-width : 1366px) {
      #pro_section {
        flex-direction: row;
      }
    
      #work_subsection {
        width: 50%;
      }
      
      #personal_subsection {
        width: 40%;
      }
    
      #subsection_split {
        align-self: center;
        height: 60%;
        width: 1px;
        background-color: #3b3a3f
      }
    }
    
    @media (max-width : 1366px) {
      #pro_section {
        flex-direction: column;
      }
    
      #subsection_split {
        display: none;
      }
    }
    
    @media (max-width : 900px) {
      body {
        flex-direction: column;
      }
    
      #perso_section {
        padding-bottom: 16px;
      }
    
      #pro_section {
        flex-direction: column;
      }
    
      #subsection_split {
        display: none;
      }
    }
  }
  