@page {
  size: A4;
  margin: 0;
}

@media print {
  html, body {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  body {
    flex-direction: row;
    display: flex;
  }

  #pro_section {
    flex-direction: column;
  }

  .page {
    height: 296.8mm;
    margin: 0;
    padding: 0;
  }

  #perso_section {
    flex: 1;
    min-width: 30vw;
  }

  #subsection_split {
    display: none;
  }
}

@media not print {
  @media (width >= 1366px) {
    #pro_section {
      flex-direction: row;
    }

    #work_subsection {
      width: 50%;
    }

    #personal_subsection {
      width: 40%;
    }

    #subsection_split {
      background-color: #3b3a3f;
      align-self: center;
      width: 1px;
      height: 60%;
    }
  }

  @media (width <= 1366px) {
    #pro_section {
      flex-direction: column;
    }

    #subsection_split {
      display: none;
    }
  }

  @media (width <= 900px) {
    body {
      flex-direction: column;
    }

    #perso_section {
      padding-bottom: 16px;
    }

    #pro_section {
      flex-direction: column;
    }

    #subsection_split {
      display: none;
    }
  }
}

/*# sourceMappingURL=index.6ab9d071.css.map */
